import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_baak = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
      {
        path: "/akun/manageuser",
        name: "Management User",
        component: () => import("@/views/akun/ManagementUser.vue"),
      },
      {
        path: "/akun/managerole",
        name: "Management Role",
        component: () => import("@/views/akun/ManagementRole.vue"),
      },
    ],
  },

  //adm akademik
  {
    path: "/adm-akademik",
    name: "Administrasi Akademik",
    component: LayoutCard,
    redirect: "/adm-akademik/beranda",
    children: [
      {
        path: "/adm-akademik/beranda",
        name: "Beranda Adm Akademik",
        component: () => import("@/views/adm_akademik/Beranda.vue"),
      },
      {
        path: "/adm-akademik/kabarberita",
        name: "Kabar Berita",
        component: () => import("@/views/adm_akademik/KabarBerita.vue"),
      },
      {
        path: "/adm-akademik/master",
        name: "Master",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/adm-akademik/master/kurikulum",
        children: [
          {
            path: "/adm-akademik/master/kurikulum",
            name: "Tahun Kurikulum",
            component: () => import("@/views/adm_akademik/Kurikulum.vue"),
          },
          {
            path: "/adm-akademik/master/thnajar",
            name: "Tahun Ajar",
            component: () => import("@/views/adm_akademik/TahunAjar.vue"),
          },
          {
            path: "/adm-akademik/master/semester",
            name: "Semester",
            component: () => import("@/views/adm_akademik/Semester.vue"),
          },
          {
            path: "/adm-akademik/master/fakultas",
            name: "Fakultas",
            component: () => import("@/views/adm_akademik/Fakultas.vue"),
          },
          {
            path: "/adm-akademik/master/prodi",
            name: "Program Studi",
            component: () => import("@/views/adm_akademik/Prodi.vue"),
          },
          {
            path: "/adm-akademik/master/kelas",
            name: "Kelas",
            component: () => import("@/views/adm_akademik/Kelas.vue"),
          },
          {
            path: "/adm-akademik/master/kelas-praktikum",
            name: "Kelas Praktikum",
            component: () => import("@/views/adm_akademik/KelasPraktikum.vue"),
          },
          {
            path: "/adm-akademik/master/ruangan",
            name: "Ruangan",
            component: () => import("@/views/adm_akademik/Ruangan.vue"),
          },
          {
            path: "/adm-akademik/master/waktu",
            name: "Waktu",
            component: () => import("@/views/adm_akademik/Waktu.vue"),
          },
          {
            path: "/adm-akademik/master/shift",
            name: "Shift",
            component: () => import("@/views/adm_akademik/Shift.vue"),
          },
          {
            path: "/adm-akademik/master/kodematkul",
            name: "Kode Mata Kuliah",
            component: () => import("@/views/adm_akademik/KodeMatkul.vue"),
          },
          {
            path: "/adm-akademik/master/komponennilai",
            name: "Komponen Penialaian",
            component: () => import("@/views/adm_akademik/KomponenNilai.vue"),
          },
        ],
      },
      {
        path: "/adm-akademik/pengaturan",
        name: "Pengaturan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/adm-akademik/pengaturan/jadwalkrs",
        children: [
          {
            path: "/adm-akademik/pengaturan/jadwalkrs",
            name: "Jadwal Registrasi KRS",
            component: () => import("@/views/adm_akademik/JadwalRegisKRS.vue"),
          },
          {
            path: "/adm-akademik/pengaturan/jadwalpmb",
            name: "Jadwal Registrasi PMB",
            component: () => import("@/views/adm_akademik/JadwalRegisPMB.vue"),
          },
          {
            path: "/adm-akademik/pengaturan/jadwalinputnilai",
            name: "Jadwal Input Nilai",
            component: () =>
              import("@/views/adm_akademik/JadwalInputNilai.vue"),
          },
        ],
      },
    ],
  },

  //beasiswa
  {
    path: "/beasiswa",
    name: "Beasiswa",
    component: LayoutCard,
    redirect: "/beasiswa/beasiswamahasiswa",
    children: [
      {
        path: "/beasiswa/beasiswamahasiswa",
        name: "Beasiswa Mahasiswa",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
            name: "Jenis Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/editaturan/:id",
            name: "Edit Aturan Beasiswa",
            component: () => import("@/views/beasiswa/EditAturanBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
            name: "Penerima Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswaMahasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/beasiswamaba",
        name: "Potongan Mahasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamaba/jenispotongan",
            name: "Jenis Potongan",
            component: () =>
              import("@/views/beasiswa/BeasiswaCalonMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
            name: "Lihat Penerima Potongan",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/laporanbeasiswa",
        name: "Laporan Beasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
        children: [
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
            name: "Laporan Beasiswa Per Semester",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerSemester.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
            name: "Laporan Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/LaporanPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
            name: "Laporan Per Prodi",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerProdi.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpotongan",
            name: "Laporan Potongan",
            component: () => import("@/views/beasiswa/LaporanPotongan.vue"),
          },
        ],
      },
    ],
  },

  //registrasi
  {
    path: "/registrasi",
    name: "Registrasi",
    component: LayoutCard,
    redirect: "/registrasi/beranda",
    children: [
      {
        path: "/registrasi/beranda",
        name: "Beranda Registrasi",
        component: () => import("@/views/Registrasi_Kaprodi/Beranda.vue"),
      },
      {
        path: "/registrasi/pilihmahasiswa",
        name: "Pilih Mahasiswa",
        component: () => import("@/views/Registrasi_Kaprodi/Mahasiswa.vue"),
      },
      {
        path: "/registrasi/tambahkrs/:id",
        name: "Tambah KRS",
        component: () => import("@/views/Registrasi_Kaprodi/TambahKRS.vue"),
      },
      {
        path: "/registrasi/editkrs/:id/:prodi",
        name: "Edit KRS",
        component: () => import("@/views/Registrasi_Kaprodi/EditKRS.vue"),
      },
      {
        path: "/registrasi/registrasimatkulacc/:id",
        name: "Registrasi Mata Kuliah ACC",
        component: () => import("@/views/registrasi/RegistrasiMatkul.vue"),
      },
      {
        path: "/registrasi/jadwal",
        name: "Jadwal",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/jadwal/jadwalujian",
        children: [
          {
            path: "/jadwal/lihatjadwal",
            name: "lihat Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/LihatJadwal.vue"),
          },
          {
            path: "/jadwal/daftarmahasiswamatkul/:id",
            name: "Daftar Mahasiswa Mata Kuliah",
            component: () =>
              import("@/views/Jadwal_Kaprodi/DaftarMahasiswaMatkul.vue"),
          },
          {
            path: "/jadwal/editjadwal/:id",
            name: "Edit Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/EditJadwal.vue"),
          },
          // {
          //   path: '/jadwal/ruangkosong',
          //   name: 'Ruang Kosong',
          //   component: () => import('@/views/Jadwal_Kaprodi/RuangKosong.vue'),
          // },
          {
            path: "/jadwal/importjadwal",
            name: "Import Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/ImportJadwal.vue"),
          },
        ],
      },
      {
        path: "/registrasi/ujian",
        name: "Ujian",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/ujian/jadwalujian",
        children: [
          {
            path: "/registrasi/ujian/jadwalujian",
            name: "Jadwal Ujian",
            component: () => import("@/views/registrasi/ujian/JadwalUjian.vue"),
          },
          {
            path: "/registrasi/ujian/importjadwalujian",
            name: "Import Jadwal Ujian",
            component: () =>
              import("@/views/Jadwal_Kaprodi/ImportJadwalUjian.vue"),
          },
          {
            path: "/registrasi/ujian/ujiansusulan",
            name: "Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/UjianSusulan.vue"),
          },
          {
            path: "/registrasi/ujian/registrasiujiansusulan/:",
            name: "Registrasi Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/RegistrasiUjianSusulan.vue"),
          },
          {
            path: "/registrasi/ujian/cetakkartu",
            name: "Cetak Kartu Ujian",
            component: () =>
              import("@/views/registrasi/ujian/CetakKartuUjian.vue"),
          },
          {
            path: "/registrasi/ujian/cetakdokumen",
            name: "Cetak Dokumen Ujian",
            component: () =>
              import("@/views/registrasi/ujian/CetakDokumen.vue"),
          },
          {
            path: "/registrasi/ujian/cetakdokumensusulan",
            name: "Cetak Dokumen Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/CetakDokumenSusulan.vue"),
          },
        ],
      },
      {
        path: "/registrasi/matkul",
        name: "Mata Kuliah Tawar",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/matkul/daftarmatkul",
        children: [
          {
            path: "/registrasi/matkul/daftarmatkul",
            name: "Lihat Mata Kuliah Tawar",
            component: () =>
              import("@/views/Registrasi_Kaprodi/DaftarMatKul.vue"),
          },
          // {
          //   path: "/registrasi/matkul/tambahmatkul",
          //   name: "Tambah Mata Kuliah ",
          //   component: () =>
          //     import("@/views/Registrasi_Kaprodi/TambahMatKul.vue"),
          // },
          {
            path: "/registrasi/matkul/impormatkul",
            name: "Impor Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/ImporMataKuliah.vue"),
          },
        ],
      },
    ],
  },

  //kurikulum
  {
    path: "/kurikulum",
    name: "Kurikulum",
    component: LayoutCard,
    redirect: "/kurikulum/beranda",
    children: [
      {
        path: "/kurikulum/beranda",
        name: "Beranda Kurikulum",
        component: () => import("@/views/kurikulum_kaprodi/Beranda.vue"),
      },
      {
        path: "/kurikulum/kelolakurikulum",
        name: "Kelola Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/kelolakurikulum/lihatkurikulum",
        children: [
          {
            path: "/kurikulum/kelolakurikulum/lihatkurikulum",
            name: "Lihat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LihatKurikulum.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/tambahmatkul",
            name: "Tambah Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/TambahMataKuliah.vue"),
          },
          // {
          //   path: "/kurikulum/kelolakurikulum/peminatanprodi",
          //   name: "Kelola Peminatan Prodi",
          //   component: () =>
          //     import("@/views/kurikulum_kaprodi/KelolaPeminatanProdi.vue"),
          // },
          {
            path: "/kurikulum/kelolakurikulum/impormatkul",
            name: "Impor Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/ImporMatkul.vue"),
          },
          // {
          //   path: "/kurikulum/kelolakurikulum/editmatakuliah/:id",
          //   name: "Detail Edit Mata Kuliah",
          //   component: () =>
          //     import("@/views/kurikulum_kaprodi/EditMataKuliah.vue"),
          // },
        ],
      },
      {
        path: "/kurikulum/persetujuankurikulum",
        name: "Persetujuan Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
        children: [
          {
            path: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
            name: "Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/RiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detail/:id_kurikulum/:id_prodi",
            name: "Detail Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailRiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detailmatkul/:id",
            name: "Detail Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailMatkul.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/aturapproval",
            name: "Atur Approval",
            component: () =>
              import("@/views/kurikulum_kaprodi/AturApproval.vue"),
          },
          {
            path: "/kurikulum/logkurikulum/:id",
            name: "Log Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LogKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/pilihtipe/:id",
            name: "Pilih Tipe",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/PilihTipe.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/editmatkul/:id",
            name: "Edit Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/Edit.vue"),
          },
        ],
      },
      // {
      //   path: "/kurikulum/silabus",
      //   name: "Silabus Kaprodi",
      //   component: {
      //     render() {
      //       return h(resolveComponent("router-view"));
      //     },
      //   },
      //   redirect: "/kurikulum/silabus/aturrps",
      //   children: [
      //     {
      //       path: "/kurikulum/silabus/aturrps",
      //       name: "Atur RPS Kaprodi",
      //       component: () => import("@/views/kurikulum_kaprodi/AturRPS.vue"),
      //     },
      //     {
      //       path: "/kurikulum/silabus/aturrps/aturgbpp/:id",
      //       name: "Atur GBPP Kaprodi",
      //       component: () => import("@/views/kurikulum_kaprodi/AturGBPP.vue"),
      //     },
      //     {
      //       path: "/kurikulum/silabus/aturrps/atursap/:id",
      //       name: "Atur SAP Kaprodi",
      //       component: () => import("@/views/kurikulum_kaprodi/AturSAP.vue"),
      //     },
      //     {
      //       path: "/kurikulum/silabus/lihatrps",
      //       name: "Lihat RPS Kaprodi",
      //       component: () => import("@/views/kurikulum_kaprodi/LihatRPS.vue"),
      //     },
      //     {
      //       path: "/kurikulum/silabus/lihatrps/lihatgbpp/:id",
      //       name: "Lihat GBPP Kaprodi",
      //       component: () => import("@/views/kurikulum_kaprodi/LihatGBPP.vue"),
      //     },
      //     {
      //       path: "/kurikulum/silabus/lihatrps/lihatsap/:id",
      //       name: "Lihat SAP Kaprodi",
      //       component: () => import("@/views/kurikulum_kaprodi/LihatSAP.vue"),
      //     },
      //     {
      //       path: "/kurikulum/silabus/statusinputrps",
      //       name: "Status Input RPS Kaprodi",
      //       component: () =>
      //         import("@/views/kurikulum_kaprodi/StatusInputRPS.vue"),
      //     },
      //     {
      //       path: "/kurikulum/silabus/ubahrps",
      //       name: "Ubah RPS Kaprodi",
      //       component: () => import("@/views/kurikulum_kaprodi/UbahRPS.vue"),
      //     },
      //   ],
      // },
      {
        path: "/kurikulum/laporanmatkul",
        name: "Laporan Mata Kuliah",
        component: () => import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
      },
      // {
      //   path: "/kurikulum/laporanmatkul/:id",
      //   name: "Laporan Matkul",
      //   component: () => import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
      // },
      {
        path: "/kurikulum/usermanual",
        name: "User Manual",
        component: () => import("@/views/kurikulum_kaprodi/ManualKaprodi.vue"),
      },
    ],
  },

  //pmb
  {
    path: "/pmb",
    name: "PMB",
    component: LayoutCard,
    redirect: "/pmb/beranda",
    children: [
      {
        path: "/pmb/beranda",
        name: "Beranda PMB",
        component: () => import("@/views/PMB/Beranda.vue"),
      },
      {
        path: "/pmb/daftarPeserta",
        name: "Daftar Peserta",
        component: () => import("@/views/PMB/daftarPeserta.vue"),
      },
      {
        path: "/pmb/pengunduranDiri",
        name: "Form Pengunduran Diri",
        component: () => import("@/views/PMB/pengunduranDiri.vue"),
      },
      {
        path: "/pmb/viewdatapeserta/:id",
        name: "View Data Peserta",
        component: () => import("@/views/PMB/ViewDataPeserta.vue"),
      },
      {
        path: "/pmb/mengisidatapeserta/:id",
        name: "Mengisi Data Peserta",
        component: () => import("@/views/PMB/MengisiDataPeserta.vue"),
      },
      {
        path: "/pmb/daftarPesertaOnSite",
        name: "Daftar Peserta Registrasi On Site",
        component: () => import("@/views/PMB/daftarPesertaOnSite.vue"),
      },
      {
        path: "/pmb/registrasi/check/:id_mahasiswa/:id_tagihan",
        name: "Registrasi Check",
        component: () => import("@/views/pmb_adm/RegistrasiCheck.vue"),
      },

      {
        path: "/pmb/pembayaran",
        name: "Pembayaran PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/pembayaran/InputPembayaran",
        children: [
          {
            path: "/pmb/pembayaran/InputPembayaran",
            name: "Input Pembayaran",
            component: () =>
              import("@/views/PMB/pembayaran/InputPembayaran.vue"),
          },
          {
            path: "/pmb/statuspembayaran/:id",
            name: "Status Pembayaran",
            component: () => import("@/views/PMB/StatusPembayaran.vue"),
          },
        ],
      },
      {
        path: "/pmb/Laporan",
        name: "Laporan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/laporan",
        children: [
          {
            path: "/pmb/Laporan/laporanPembayaran",
            name: "Laporan Pembayaran PMB",
            component: () =>
              import("@/views/PMB/Laporan/laporanPembayaran.vue"),
          },
          {
            path: "/pmb/Laporan/PesertaPmb",
            name: "Laporan Peserta PMB",
            component: () => import("@/views/PMB/Laporan/PesertaPmb.vue"),
          },
        ],
      },

      {
        path: "/pmb/pengaturan",
        name: "Pengaturan PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/pengaturan",
        children: [
          {
            path: "/pmb/pengaturan/generetekelas",
            name: "Generate Kelas",
            component: () => import("@/views/PMB/Pengaturan/GenereteKelas.vue"),
          },
          {
            path: "/pmb/pengaturan/jadwalseleksireg",
            name: "Jadwal Seleksi Registrasi",
            component: () =>
              import("@/views/PMB/Pengaturan/JadwalRegistrasi.vue"),
          },
        ],
      },
      {
        path: "/pmb/bantuan",
        name: "Bantuan PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/bantuan",
        children: [
          {
            path: "/pmb/bantuan/Manual",
            name: "Download Manual Pengguna",
            component: () => import("@/views/PMB/Bantuan/Manual.vue"),
          },
        ],
      },
    ],
  },

  //data-mahasiswa
  {
    path: "/data-mahasiswa",
    name: "Data Mahasiswa",
    component: LayoutCard,
    redirect: "/data-mahasiswa/perubahan-data",
    children: [
      {
        path: "/data-mahasiswa/perubahan-data",
        name: "Perubahan Data",
        component: () => import("@/views/DataMahasiswa/PerubahanData.vue"),
      },
      {
        path: "/data-mahasiswa/perubahan-data/:id",
        name: "Detail Perubahan Data",
        component: () =>
          import("@/views/DataMahasiswa/DetailPerubahanData.vue"),
      },
      {
        path: "/data-mahasiswa/jumlah-mahasiswa",
        name: "Jumlah Mahasiswa",
        component: () => import("@/views/DataMahasiswa/JumlahMahasiswa.vue"),
      },
      {
        path: "/data-mahasiswa/detail-data-mahasiswa/:id",
        name: "Detail Data Mahasiswa",
        component: () =>
          import("@/views/DataMahasiswa/DetailDataMahasiswa.vue"),
      },
    ],
  },

  //presensi baak
  {
    path: "/presensi-baak",
    name: "Presensi BAAK",
    component: LayoutCard,
    redirect: "/presensi-baak/beranda",
    children: [
      // {
      //   path: "/presensi-baak/beranda",
      //   name: "Beranda Presensi BAAK",
      //   component: () => import("@/views/presensi_baak/Beranda.vue"),
      // },
      {
        path: "/presensi-baak/bap",
        name: "Berita Acara Perkuliahan BAAK",
        component: () => import("@/views/presensi_baak/BAP.vue"),
      },
      {
        path: "/presensi-baak/submit-bap/:id",
        name: "Submit BAP BAAK",
        component: () => import("@/views/presensi_baak/SubmitBAP.vue"),
      },
      {
        path: "/presensi-baak/cetakdaftarhadir",
        name: "Cetak Daftar Hadir BAAK",
        component: () => import("@/views/presensi_baak/CetakDaftarHadir.vue"),
      },
      {
        path: "/presensi-baak/jadwaldosen",
        name: "Jadwal Dosen Presensi BAAK",
        component: () => import("@/views/presensi_baak/JadwalDosen.vue"),
      },
      {
        path: "/presensi-baak/daftarmahasiswamk/:id_mt/:id_jadwal",
        name: "Daftar Mahasiswa Mata Kuliah BAAK",
        component: () => import("@/views/presensi_baak/DaftarMahasiswaMK.vue"),
      },
      {
        path: "/presensi-baak/laporanperkuliahan/rekapkehadirandosen",
        name: "Rekap Kehadiran Dosen BAAK",
        component: () =>
          import("@/views/Presensi_Kaprodi/RekapKehadiranDosen.vue"),
      },
      {
        path: "/presensi-baak/laporanperkuliahan/rekapkehadirandosen/:id_dosen/:id_mata_kuliah_tawar",
        name: "Detail Rekap Kehadiran Dosen BAAK",
        component: () =>
          import("@/views/Presensi_Kaprodi/DetailRekapKehadiranDosen.vue"),
      },
      {
        path: "/presensi-baak/laporanperkuliahan/rekapkehadiranmahasiswa",
        name: "Rekap Kehadiran Mahasiswa BAAK",
        component: () =>
          import("@/views/presensi_baak/RekapKehadiranMahasiswa.vue"),
      },
      {
        path: "/presensi-baak/laporanperkuliahan/rekapkehadiranmahasiswa/:id_krs/:id_mahasiswa",
        name: "Detail Rekap Kehadiran Mahasiswa BAAK",
        component: () =>
          import("@/views/presensi_baak/DetailRekapKehadiranMahasiswa.vue"),
      },
      {
        path: "/presensi-baak/evaluasipembelajaran",
        name: "Evaluasi Pembelajaran BAAK",
        component: () =>
          import("@/views/Dosen/presensi/EvaluasiPembelajaran.vue"),
      },
    ],
  },

  //kuesioner
  {
    path: "/kuesioner",
    name: "Kuesioner",
    component: LayoutCard,
    redirect: "/kuesioner/beranda",
    children: [
      {
        path: "/kuesioner/beranda",
        name: "Beranda Kuesioner",
        component: () => import("@/views/kuesioner/Beranda.vue"),
      },
      {
        path: "/kuesioner/lihat-kuesioner",
        name: "Lihat Kuesioner",
        component: () => import("@/views/kuesioner/LihatKuesioner.vue"),
      },
      {
        path: "/kuesioner/detail-kuesioner/:id",
        name: "Detail Kuesioner",
        component: () => import("@/views/kuesioner/DetailKuesioner.vue"),
      },
    ],
  },

  //nilai dosen baak
  {
    path: "/nilaibaak",
    name: "Nilai BAAK",
    component: LayoutCard,
    redirect: "/nilaibaak/berandanilai",
    children: [
      {
        path: "/nilaibaak/berandanilai",
        name: "Beranda Nilai BAAK",
        component: () => import("@/views/Nilai_Dosen_BAAK/Beranda.vue"),
      },
      {
        path: "/nilaibaak/riwayatinput",
        name: "Riwayat Input Nilai BAAK",
        component: () => import("@/views/Nilai_Dosen_BAAK/Riwayat.vue"),
      },
      {
        path: "/nilaibaak/pilih-mahasiswa",
        name: "Nilai Mahasiswa",
        component: () => import("@/views/nilai/PilihMahasiswa.vue"),
      },
      {
        path: "/nilai/khs/lihatnilai/:id",
        name: "Lihat Nilai Mahasiswa",
        component: () => import("@/views/nilai/LihatNilai.vue"),
      },
    ],
  },

  // baak
  // {
  //   path: "/registrasi",
  //   name: "Registrasi",
  //   component: LayoutCard,
  //   redirect: "/registrasi/jadwal",
  //   children: [
  //     {
  //       path: "/registrasi/jadwal",
  //       name: "Jadwal",
  //       component: {
  //         render() {
  //           return h(resolveComponent("router-view"));
  //         },
  //       },
  //       redirect: "/registrasi/jadwal/jadwalujian",
  //       children: [
  //         {
  //           path: "/registrasi/jadwal/jadwalujian",
  //           name: "Lihat Jadwal Ujian",
  //           component: () => import("@/views/registrasi/LihatJadwalUjian.vue"),
  //         },
  //         {
  //           path: "/registrasi/jadwal/importjadwalujian",
  //           name: "Import Jadwal Ujian",
  //           component: () => import("@/views/registrasi/ImportJadwalUjian.vue"),
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes_baak;
