import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_kpj = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
    ],
  },

  //manajemen SDM
  {
    path: "/manajemen-sdm",
    name: "Manajemen SDM",
    component: LayoutCard,
    redirect: "/manajemen-sdm/beranda",
    children: [
      {
        path: "/manajemen-sdm/beranda",
        name: "Beranda Manajemen SDM",
        component: () => import("@/views/Admin_SDM/Beranda.vue"),
      },
      {
        path: "/manajemen-sdm/data-pegawai",
        name: "Data Pegawai",
        component: () => import("@/views/pegawai/DataPegawai.vue"),
      },
      {
        path: "/manajemen-sdm/struktur",
        name: "Struktur Organisasi",
        component: () =>
          import("@/views/Admin_SDM/LihatStrukturOrganisasi.vue"),
      },
      {
        path: "/manajemen-sdm/riwayat-jabatan/:id",
        name: "Riwayat Jabatan",
        component: () => import("@/views/Admin_SDM/RiwayatJabatan.vue"),
      },
      {
        path: "/manajemen-sdm/rekap-kehadiran-dosen",
        name: "Rekapitulasi Kehadiran Dosen",
        component: () =>
          import("@/views/Admin_SDM/RekapitulasiKehadiranDosen.vue"),
      },
      {
        path: "/manajemen-sdm/log-export-kehadiran",
        name: "Log Export Kehadiran",
        component: () => import("@/views/Admin_SDM/LogExportKehadiran.vue"),
      },
    ],
  },

  // baak
  // {
  //   path: "/registrasi",
  //   name: "Registrasi",
  //   component: LayoutCard,
  //   redirect: "/registrasi/jadwal",
  //   children: [
  //     {
  //       path: "/registrasi/jadwal",
  //       name: "Jadwal",
  //       component: {
  //         render() {
  //           return h(resolveComponent("router-view"));
  //         },
  //       },
  //       redirect: "/registrasi/jadwal/jadwalujian",
  //       children: [
  //         {
  //           path: "/registrasi/jadwal/jadwalujian",
  //           name: "Lihat Jadwal Ujian",
  //           component: () => import("@/views/registrasi/LihatJadwalUjian.vue"),
  //         },
  //         {
  //           path: "/registrasi/jadwal/importjadwalujian",
  //           name: "Import Jadwal Ujian",
  //           component: () => import("@/views/registrasi/ImportJadwalUjian.vue"),
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes_kpj;
