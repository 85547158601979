import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_mahasiswa = [
  // Mahasiswa
  // {
  //   path: "/registrasi",
  //   name: "Registrasi",
  //   component: LayoutCard,
  //   redirect: "/registrasi/jadwal",
  //   children: [
  //     {
  //       path: "/registrasi/jadwal",
  //       name: "Jadwal",
  //       component: {
  //         render() {
  //           return h(resolveComponent("router-view"));
  //         },
  //       },
  //       redirect: "/registrasi/jadwal/jadwalmhs",
  //       children: [
  //         {
  //           path: "/registrasi/jadwal/jadwalujianmhs",
  //           name: "Jadwal Ujian Mahasiswa",
  //           component: () =>
  //             import("@/views/registrasi/JadwalUjianMahasiswa.vue"),
  //         },
  //       ],
  //     },
  //   ],
  // },

  //akun
  {
    path: "/akun",
    name: "Akun Mahasiswa",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profil Saya",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/editprofil",
            name: "Edit Profile",
            component: () => import("@/views/akun/Profile.vue"),
          },
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
    ],
  },

  //registrasi
  {
    path: "/registrasi",
    name: "Registrasi",
    component: LayoutCard,
    redirect: "/registrasi/beranda",
    children: [
      {
        path: "/registrasi/beranda",
        name: "Beranda Registrasi",
        component: () => import("@/views/registrasi/Beranda.vue"),
      },
      {
        path: "/registrasi/tagihanregis",
        name: "Tagihan Registrasi",
        component: () => import("@/views/registrasi/TagihanRegistrasi.vue"),
      },
      {
        path: "/registrasi/detailpembayaranregistrasi/:id",
        name: "Detail Pembayaran Registrasi",
        component: () => import("@/views/registrasi/DetailPembayaran.vue"),
      },
      {
        path: "/registrasi/registrasimatkul",
        name: "Registrasi Mata Kuliah",
        component: () => import("@/views/registrasi/KRS.vue"),
      },
      {
        path: "/registrasi/registrasimatkulacc/:id",
        name: "Registrasi Mata Kuliah ACC",
        component: () => import("@/views/registrasi/RegistrasiMatkul.vue"),
      },
      {
        path: "/registrasi/statusregis",
        name: "Status Registrasi",
        component: () => import("@/views/registrasi/StatusRegistrasi.vue"),
      },
      {
        path: "/registrasi/jadwal",
        name: "Jadwal",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/jadwal/jadwalujian",
        children: [
          {
            path: "/registrasi/jadwal/jadwalmhs",
            name: "Jadwal Mahasiswa",
            component: () => import("@/views/registrasi/JadwalMahasiswa.vue"),
          },
          {
            path: "/registrasi/jadwal/jadwalujianmhs",
            name: "Jadwal Ujian Mahasiswa",
            component: () =>
              import("@/views/registrasi/JadwalUjianMahasiswa.vue"),
          },
        ],
      },
      {
        path: "/registrasi/ujian",
        name: "Ujian Susulan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/ujian/registrasiujiansusulan",
        children: [
          {
            path: "/registrasi/ujian/registrasiujiansusulan",
            name: "Registrasi Ujian Susulan",
            component: () =>
              import(
                "@/views/registrasi/ujian/RegistrasiUjianSusulanMahasiswa.vue"
              ),
          },
        ],
      },
      {
        path: "/registrasi/kuesioner/:id",
        name: "Kuesioner",
        component: () => import("@/views/registrasi/Kuesioner.vue"),
      },
    ],
  },

  //nilai
  {
    path: "/nilai",
    name: "Nilai",
    component: LayoutCard,
    redirect: "/nilai/beranda",
    children: [
      {
        path: "/nilai/beranda",
        name: "Beranda Nilai",
        component: () => import("@/views/nilai/Beranda.vue"),
      },
      {
        path: "/nilai/khs",
        name: "KHS",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/nilai/khs/lihatekivalensi",
        children: [
          // {
          //   path: '/nilai/khs/lihatekivalensi',
          //   name: 'Lihat Ekivalensi',
          //   component: () => import('@/views/nilai/LihatEkivalensi.vue'),
          // },
          {
            path: "/nilai/khs/lihatnilai",
            name: "Lihat Nilai",
            component: () => import("@/views/nilai/LihatNilai.vue"),
          },
          // {
          //   path: '/nilai/khs/lihatnilaisementara',
          //   name: 'Lihat Nilai Sementara',
          //   component: () => import('@/views/nilai/LihatNilaiSementara.vue'),
          // },
        ],
      },
      // {
      //   path: '/nilai/riwayatinput',
      //   name: 'Riwayat Input Nilai ',
      //   component: () => import('@/views/Nilai_Dosen/Riwayat.vue'),
      // },
      // {
      //   path: '/nilai/listmatkul',
      //   name: 'List Mata Kuliah ',
      //   component: () => import('@/views/Nilai_Dosen/ListMatkul.vue'),
      // },
      // {
      //   path: '/nilai/inputnilai/:id',
      //   name: 'Input Nilai ',
      //   component: () => import('@/views/Nilai_Dosen/InputNilai.vue'),
      // },
    ],
  },

  //presensi
  {
    path: "/presensi",
    name: "Presensi Mahasiswa",
    component: LayoutCard,
    redirect: "/presensi/rekap-kuliah",
    children: [
      {
        path: "/presensi/rekap-kuliah",
        name: "Rekap Kuliah",
        component: () => import("@/views/presensi-mhs/RekapKuliah.vue"),
      },
      {
        path: "/presensi/rekap-Kuliah/:id",
        name: "Detail Rekap Kuliah",
        component: () => import("@/views/presensi-mhs/DetailRekapKuliah.vue"),
      },
    ],
  },
];

export default routes_mahasiswa;
