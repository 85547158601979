import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_marketing = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
      {
        path: "/akun/manageuser",
        name: "Management User",
        component: () => import("@/views/akun/ManagementUser.vue"),
      },
      {
        path: "/akun/managerole",
        name: "Management Role",
        component: () => import("@/views/akun/ManagementRole.vue"),
      },
    ],
  },

  //pmb
  {
    path: "/pmb",
    name: "PMB",
    component: LayoutCard,
    redirect: "/pmb/beranda",
    children: [
      {
        path: "/pmb/beranda",
        name: "Beranda PMB",
        component: () => import("@/views/PMB/Beranda.vue"),
      },
      {
        path: "/pmb/daftarPeserta",
        name: "Daftar Peserta",
        component: () => import("@/views/PMB/daftarPeserta.vue"),
      },
      {
        path: "/pmb/pengunduranDiri",
        name: "Form Pengunduran Diri",
        component: () => import("@/views/PMB/pengunduranDiri.vue"),
      },
      {
        path: "/pmb/viewdatapeserta/:id",
        name: "View Data Peserta",
        component: () => import("@/views/PMB/ViewDataPeserta.vue"),
      },
      {
        path: "/pmb/mengisidatapeserta/:id",
        name: "Mengisi Data Peserta",
        component: () => import("@/views/PMB/MengisiDataPeserta.vue"),
      },
      {
        path: "/pmb/daftarPesertaOnSite",
        name: "Daftar Peserta Registrasi On Site",
        component: () => import("@/views/PMB/daftarPesertaOnSite.vue"),
      },
      {
        path: "/pmb/registrasi/check/:id_mahasiswa/:id_tagihan",
        name: "Registrasi Check",
        component: () => import("@/views/pmb_adm/RegistrasiCheck.vue"),
      },

      {
        path: "/pmb/pembayaran",
        name: "Pembayaran PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/pembayaran/InputPembayaran",
        children: [
          {
            path: "/pmb/pembayaran/InputPembayaran",
            name: "Input Pembayaran",
            component: () =>
              import("@/views/PMB/pembayaran/InputPembayaran.vue"),
          },
          {
            path: "/pmb/statuspembayaran/:id",
            name: "Status Pembayaran",
            component: () => import("@/views/PMB/StatusPembayaran.vue"),
          },
        ],
      },
      {
        path: "/pmb/Laporan",
        name: "Laporan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/laporan",
        children: [
          {
            path: "/pmb/Laporan/laporanPembayaran",
            name: "Laporan Pembayaran PMB",
            component: () =>
              import("@/views/PMB/Laporan/laporanPembayaran.vue"),
          },
          {
            path: "/pmb/Laporan/PesertaPmb",
            name: "Laporan Peserta PMB",
            component: () => import("@/views/PMB/Laporan/PesertaPmb.vue"),
          },
        ],
      },

      {
        path: "/pmb/pengaturan",
        name: "Pengaturan PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/pengaturan",
        children: [
          {
            path: "/pmb/pengaturan/generetekelas",
            name: "Generate Kelas",
            component: () => import("@/views/PMB/Pengaturan/GenereteKelas.vue"),
          },
          {
            path: "/pmb/pengaturan/jadwalseleksireg",
            name: "Jadwal Seleksi Registrasi",
            component: () =>
              import("@/views/PMB/Pengaturan/JadwalRegistrasi.vue"),
          },
        ],
      },
      {
        path: "/pmb/bantuan",
        name: "Bantuan PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/bantuan",
        children: [
          {
            path: "/pmb/bantuan/Manual",
            name: "Download Manual Pengguna",
            component: () => import("@/views/PMB/Bantuan/Manual.vue"),
          },
        ],
      },
    ],
  },

  //beasiswa
  {
    path: "/beasiswa",
    name: "Beasiswa",
    component: LayoutCard,
    redirect: "/beasiswa/lihatjenisbeasiswa",
    children: [
      {
        path: "/beasiswa/lihatjenisbeasiswa",
        name: "Lihat Jenis Beasiswa",
        component: () => import("@/views/beasiswa/LihatJenisBeasiswa.vue"),
      },
      {
        path: "/beasiswa/beasiswamahasiswa",
        name: "Beasiswa Mahasiswa",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
            name: "Lihat Jenis Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/editaturan/:id",
            name: "Edit Aturan Beasiswa",
            component: () => import("@/views/beasiswa/EditAturanBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/tambahbeasiswa",
            name: "Tambah Beasiswa",
            component: () => import("@/views/beasiswa/TambahBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswa",
            name: "Penerima Beasiswa",
            component: () => import("@/views/beasiswa/PenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
            name: "Lihat Penerima Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/importpenerima",
            name: "Import Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/ImportPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalbeasiswa",
            name: "Approval Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalstatusbeasiswa",
            name: "Approval Status Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalStatusPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/beasiswamaba",
        name: "Potongan Mahasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamaba/jenispotongan",
            name: "Potongan Calon Mahasiswa",
            component: () =>
              import("@/views/beasiswa/BeasiswaCalonMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
            name: "Lihat Jenis Potongan",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMaba.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
            name: "Lihat Penerima Potongan",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/laporanbeasiswa",
        name: "Laporan Beasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
        children: [
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
            name: "Laporan Beasiswa Per Semester",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerSemester.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
            name: "Laporan Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/LaporanPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
            name: "Laporan Per Prodi",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerProdi.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpotongan",
            name: "Laporan Potongan",
            component: () => import("@/views/beasiswa/LaporanPotongan.vue"),
          },
        ],
      },
    ],
  },
];

export default routes_marketing;
