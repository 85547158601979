import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_dekan = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
    ],
  },
  //nilai dosen
  // {
  //   path: "/nilaidosen",
  //   name: "Nilai Dosen",
  //   component: LayoutCard,
  //   redirect: "/nilaidosen/berandanilai",
  //   children: [
  //     {
  //       path: "/nilaidosen/berandanilai",
  //       name: "Beranda_Nilai ",
  //       component: () => import("@/views/Nilai_Dosen/Beranda.vue"),
  //     },
  //     {
  //       path: "/nilaidosen/riwayatinput",
  //       name: "Riwayat Input Nilai ",
  //       component: () => import("@/views/Nilai_Dosen/Riwayat.vue"),
  //     },
  //     {
  //       path: "/nilaidosen/listmatkul",
  //       name: "List Mata Kuliah ",
  //       component: () => import("@/views/Nilai_Dosen/ListMatkul.vue"),
  //     },
  //     {
  //       path: "/nilaidosen/inputnilai/:id",
  //       name: "Input Nilai ",
  //       component: () => import("@/views/Nilai_Dosen/InputNilai.vue"),
  //     },
  //     {
  //       path: "/nilaidosen/print-berita-acara/:id",
  //       name: "Cetak Berita Acara",
  //       component: () => import("@/views/Nilai_Dosen/PrintBeritaAcara.vue"),
  //     },
  //   ],
  // },

  //presensi
  // {
  //   path: "/presensi",
  //   name: "Presensi",
  //   component: LayoutCard,
  //   redirect: "/presensi/beranda",
  //   children: [
  //     {
  //       path: "/presensi/beranda",
  //       name: "Beranda Presensi",
  //       component: () => import("@/views/presensi/Beranda.vue"),
  //     },
  //     {
  //       path: "/presensi/rfidlog",
  //       name: "RFID Log",
  //       component: () => import("@/views/presensi/RFIDLog.vue"),
  //     },
  //     {
  //       path: "/presensi/kehadiran",
  //       name: "Kehadiran2",
  //       component: () => import("@/views/perkuliahan/Kehadiran.vue"),
  //     },

  //     {
  //       path: "/presensi/kehadiranmhs",
  //       name: "Kehadiran Mahasiswa",
  //       component: {
  //         render() {
  //           return h(resolveComponent("router-view"));
  //         },
  //       },
  //       redirect: "/presensi/kehadiranmhs/per-perkuliahan",
  //       children: [
  //         {
  //           path: "/presensi/kehadiranmhs/per-perkuliahan",
  //           name: "Kehadiran per Perkuliahan",
  //           component: () =>
  //             import("@/views/Dosen/presensi/KehadiranperPerkuliahan.vue"),
  //         },
  //       ],
  //     },
  //     {
  //       path: "/presensi/bap",
  //       name: "Berita Acara Perkuliahan",
  //       component: () => import("@/views/Dosen/presensi/BAP.vue"),
  //     },
  //     {
  //       path: "/presensi/submit-bap/:id",
  //       name: "Submit BAP",
  //       component: () => import("@/views/Dosen/presensi/SubmitBAP.vue"),
  //       props: true,
  //     },
  //     {
  //       path: "/presensi/cetakdaftarhadir",
  //       name: "Cetak Daftar Hadir",
  //       component: () => import("@/views/Dosen/presensi/CetakDaftarHadir.vue"),
  //     },
  //     {
  //       path: "/presensi/evaluasipembelajaran",
  //       name: "Evaluasi Pembelajaran",
  //       component: () =>
  //         import("@/views/Dosen/presensi/EvaluasiPembelajaran.vue"),
  //     },
  //     {
  //       path: "/presensi/honor",
  //       name: "Honor Kehadiran",
  //       component: () => import("@/views/Dosen/presensi/HonorKehadiran.vue"),
  //     },
  //     {
  //       path: "/presensi/jadwaldosen",
  //       name: "Jadwal Dosen Presensi",
  //       component: () => import("@/views/Dosen/presensi/JadwalDosen.vue"),
  //     },
  //     {
  //       path: "/presensi/daftarmahasiswamk/:id_mt/:id_jadwal",
  //       name: "Daftar Mahasiswa Mata Kuliah",
  //       component: () => import("@/views/Dosen/presensi/DaftarMahasiswaMK.vue"),
  //     },
  //     {
  //       path: "/presensi/laporanperkuliahan/rekapkehadirandosen",
  //       name: "Rekap Kehadiran Dosen",
  //       component: () =>
  //         import("@/views/Presensi_Kaprodi/RekapKehadiranDosen.vue"),
  //     },
  //   ],
  // },

  //nilai
  {
    path: "/nilai",
    name: "Nilai",
    component: LayoutCard,
    redirect: "/nilai/beranda",
    children: [
      {
        path: "/nilai/beranda",
        name: "Beranda Nilai",
        component: () => import("@/views/nilai/Beranda.vue"),
      },
      {
        path: "/nilai/khs",
        name: "KHS",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/nilai/khs/pilih-mahasiswa",
        children: [
          {
            path: "/nilai/khs/pilih-mahasiswa",
            name: "Pilih Nilai Mahasiswa",
            component: () => import("@/views/nilai/PilihMahasiswa.vue"),
          },
          {
            path: "/nilai/khs/lihatnilai/:id",
            name: "Lihat Nilai",
            component: () => import("@/views/nilai/LihatNilai.vue"),
          },
        ],
      },
    ],
  },
  //kurikulum
  {
    path: "/kurikulum",
    name: "Kurikulum",
    component: LayoutCard,
    redirect: "/kurikulum/beranda",
    children: [
      {
        path: "/kurikulum/beranda",
        name: "Beranda Kurikulum",
        component: () => import("@/views/kurikulum_kaprodi/Beranda.vue"),
      },
      {
        path: "/kurikulum/kelolakurikulum",
        name: "Kelola Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/kelolakurikulum/lihatkurikulum",
        children: [
          {
            path: "/kurikulum/kelolakurikulum/lihatkurikulum",
            name: "Lihat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LihatKurikulum.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/tambahmatkul",
            name: "Tambah Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/TambahMataKuliah.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/impormatkul",
            name: "Impor Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/ImporMatkul.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/editmatakuliah/:id",
            name: "Detail Edit Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/EditMataKuliah.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/persetujuankurikulum",
        name: "Persetujuan Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
        children: [
          {
            path: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
            name: "Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/RiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detail/:id_kurikulum/:id_prodi",
            name: "Detail Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailRiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detailmatkul/:id",
            name: "Detail Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailMatkul.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/aturapproval",
            name: "Atur Approval",
            component: () =>
              import("@/views/kurikulum_kaprodi/AturApproval.vue"),
          },
          {
            path: "/kurikulum/logkurikulum/:id",
            name: "Log Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LogKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/pilihtipe/:id",
            name: "Pilih Tipe",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/PilihTipe.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/editmatkul/:id",
            name: "Edit Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/Edit.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/laporanmatkul",
        name: "Laporan Mata Kuliah",
        component: () => import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
      },
      {
        path: "/kurikulum/usermanual",
        name: "User Manual",
        component: () => import("@/views/kurikulum_kaprodi/ManualKaprodi.vue"),
      },
    ],
  },
  //registrasi
  //registrasi
  {
    path: "/registrasi",
    name: "Registrasi",
    component: LayoutCard,
    redirect: "/registrasi/beranda",
    children: [
      {
        path: "/registrasi/beranda",
        name: "Beranda Registrasi",
        component: () => import("@/views/Registrasi_Kaprodi/Beranda.vue"),
      },
      {
        path: "/registrasi/pilihmahasiswa",
        name: "Pilih Mahasiswa",
        component: () => import("@/views/Registrasi_Kaprodi/Mahasiswa.vue"),
      },
      {
        path: "/registrasi/editkrs/:id/:prodi",
        name: "Edit KRS",
        component: () => import("@/views/Registrasi_Kaprodi/EditKRS.vue"),
      },
      {
        path: "/registrasi/tambahkrs/:id",
        name: "Tambah KRS",
        component: () => import("@/views/Registrasi_Kaprodi/TambahKRS.vue"),
      },
      {
        path: "/registrasi/registrasimatkulacc/:id",
        name: "Registrasi Mata Kuliah ACC",
        component: () => import("@/views/registrasi/RegistrasiMatkul.vue"),
      },
      {
        path: "/registrasi/jadwal",
        name: "Jadwal",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/jadwal/jadwalujian",
        children: [
          {
            path: "/jadwal/tambahjadwal",
            name: "Tambah Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/TambahJadwal.vue"),
          },
          {
            path: "/jadwal/lihatjadwal",
            name: "lihat Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/LihatJadwal.vue"),
          },
          {
            path: "/jadwal/daftarmahasiswamatkul/:id",
            name: "Daftar Mahasiswa Mata Kuliah",
            component: () =>
              import("@/views/Jadwal_Kaprodi/DaftarMahasiswaMatkul.vue"),
          },
          {
            path: "/jadwal/editjadwal/:id",
            name: "Edit Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/EditJadwal.vue"),
          },
          // {
          //   path: '/jadwal/ruangkosong',
          //   name: 'Ruang Kosong',
          //   component: () => import('@/views/Jadwal_Kaprodi/RuangKosong.vue'),
          // },
          {
            path: "/jadwal/importjadwal",
            name: "Import Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/ImportJadwal.vue"),
          },
        ],
      },
      {
        path: "/registrasi/ujian",
        name: "Ujian",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/ujian/jadwalujian",
        children: [
          {
            path: "/registrasi/ujian/jadwalujian",
            name: "Jadwal Ujian",
            component: () => import("@/views/registrasi/ujian/JadwalUjian.vue"),
          },
          {
            path: "/registrasi/ujian/importjadwalujian",
            name: "Import Jadwal Ujian",
            component: () =>
              import("@/views/Jadwal_Kaprodi/ImportJadwalUjian.vue"),
          },
          {
            path: "/registrasi/ujian/ujiansusulan",
            name: "Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/UjianSusulan.vue"),
          },
          {
            path: "/registrasi/ujian/registrasiujiansusulan/:",
            name: "Registrasi Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/RegistrasiUjianSusulan.vue"),
          },
          {
            path: "/registrasi/ujian/cetakdokumen",
            name: "Cetak Dokumen Ujian",
            component: () =>
              import("@/views/registrasi/ujian/CetakDokumen.vue"),
          },
          {
            path: "/registrasi/ujian/cetakdokumensusulan",
            name: "Cetak Dokumen Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/CetakDokumenSusulan.vue"),
          },
        ],
      },
      {
        path: "/registrasi/matkul",
        name: "Mata Kuliah Tawar",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/matkul/daftarmatkul",
        children: [
          {
            path: "/registrasi/matkul/daftarmatkul",
            name: "Lihat Mata Kuliah Tawar",
            component: () =>
              import("@/views/Registrasi_Kaprodi/DaftarMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/impormatkul",
            name: "Impor Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/ImporMataKuliah.vue"),
          },
        ],
      },
    ],
  },
  //finansial
  {
      path: "/finansial",
      name: "Finansial",
      component: LayoutCard,
      redirect: "/finansial/datapembayaran",
      children: [
        {
          path: "/finansial/datapembayaran",
          name: "Data Pembayaran",
          component: () =>
            import("@/views/finansial_keu/pembayaranreguler/DataPembayaran.vue"),
        },
        {
          path: "/finansial/detailpembayaran/:id",
          name: "Detail Pembayaran",
          component: () =>
            import(
              "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaran.vue"
            ),
        },
        {
          path: "/finansial/detailpembayaranmala/:id",
          name: "Detail Pembayaran MALA",
          component: () =>
            import(
              "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaranMala.vue"
            ),
        },
        {
          path: "/finansial/laporan",
          name: "Laporan Keuangan",
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          redirect: "/finansial/laporan/logva",
          children: [
            {
              path: "/finansial/laporan/logva",
              name: "Log Transaksi VA",
              component: () => import("@/views/finansial_keu/laporan/LogVA.vue"),
            },
            {
              path: "/finansial/laporan/laporanpiutangmhs",
              name: "Laporan Piutang Mahasiswa",
              component: () =>
                import(
                  "@/views/finansial_keu/laporan/LaporanPiutangMahasiswa.vue"
                ),
            },
            {
              path: "/finansial/laporan/laporanlainnya",
              name: "Log Transaksi Lainnya",
              component: () =>
                import("@/views/finansial_keu/laporan/LogTransaksi.vue"),
            },
          ],
        },
      ],
    },
];

export default routes_dekan;
