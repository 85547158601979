import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_dosen = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
    ],
  },
  //nilai dosen
  {
    path: "/nilaidosen",
    name: "Nilai Dosen",
    component: LayoutCard,
    redirect: "/nilaidosen/berandanilai",
    children: [
      {
        path: "/nilaidosen/berandanilai",
        name: "Beranda Nilai Dosen",
        component: () => import("@/views/Nilai_Dosen/Beranda.vue"),
      },
      {
        path: "/nilaidosen/riwayatinput",
        name: "Riwayat Input Nilai ",
        component: () => import("@/views/Nilai_Dosen/Riwayat.vue"),
      },
      {
        path: "/nilaidosen/listmatkul",
        name: "List Mata Kuliah ",
        component: () => import("@/views/Nilai_Dosen/ListMatkul.vue"),
      },
      {
        path: "/nilaidosen/inputnilai/:id",
        name: "Input Nilai ",
        component: () => import("@/views/Nilai_Dosen/InputNilai.vue"),
      },
      {
        path: "/nilaidosen/print-berita-acara/:id",
        name: "Cetak Berita Acara",
        component: () => import("@/views/Nilai_Dosen/PrintBeritaAcara.vue"),
      },
    ],
  },
  //presensi
  {
    path: "/presensi",
    name: "Presensi",
    component: LayoutCard,
    redirect: "/presensi/beranda",
    children: [
      {
        path: "/presensi/beranda",
        name: "Beranda Presensi",
        component: () => import("@/views/presensi/Beranda.vue"),
      },

      // {
      //   path: "/presensi/kehadiranmhs",
      //   name: "Kehadiran Mahasiswa",
      //   component: {
      //     render() {
      //       return h(resolveComponent("router-view"));
      //     },
      //   },
      //   redirect: "/presensi/kehadiranmhs/per-perkuliahan",
      //   children: [
      //     {
      //       path: "/presensi/kehadiranmhs/per-perkuliahan",
      //       name: "Kehadiran per Perkuliahan",
      //       component: () =>
      //         import("@/views/Dosen/presensi/KehadiranperPerkuliahan.vue"),
      //     },
      //   ],
      // },
      {
        path: "/presensi/bap",
        name: "Berita Acara Perkuliahan",
        component: () => import("@/views/Dosen/presensi/BAP.vue"),
      },
      {
        path: "/presensi/submit-bap/:id",
        name: "Submit BAP",
        component: () => import("@/views/Dosen/presensi/SubmitBAP.vue"),
        props: true,
      },
      {
        path: "/presensi/cetakdaftarhadir",
        name: "Cetak Daftar Hadir",
        component: () => import("@/views/Dosen/presensi/CetakDaftarHadir.vue"),
      },
      {
        path: "/presensi/evaluasipembelajaran",
        name: "Evaluasi Pembelajaran",
        component: () =>
          import("@/views/Dosen/presensi/EvaluasiPembelajaran.vue"),
      },
      {
        path: "/presensi/honor",
        name: "Honor Kehadiran",
        component: () => import("@/views/Dosen/presensi/HonorKehadiran.vue"),
      },
      {
        path: "/presensi/jadwaldosen",
        name: "Jadwal Dosen",
        component: () => import("@/views/Dosen/presensi/JadwalDosen.vue"),
      },
      {
        path: "/presensi/daftarmahasiswamk/:id_mt/:id_jadwal",
        name: "Daftar Mahasiswa Mata Kuliah",
        component: () => import("@/views/Dosen/presensi/DaftarMahasiswaMK.vue"),
      },
      {
        path: "/presensi/laporanperkuliahan/rekapkehadirandosen",
        name: "Rekap Kehadiran Dosen",
        component: () =>
          import("@/views/Presensi_Kaprodi/RekapKehadiranDosen.vue"),
      },
      {
        path: "/presensi/laporanperkuliahan/rekapkehadirandosen/:id_dosen/:id_mata_kuliah_tawar",
        name: "Detail Rekap Kehadiran Dosen",
        component: () =>
          import("@/views/Presensi_Kaprodi/DetailRekapKehadiranDosen.vue"),
      },
    ],
  },
  //kurikulum
  {
    path: "/kurikulum/koordinator-matku",
    name: "Kurikulum",
    component: LayoutCard,
    redirect: "/kurikulum/koordinator-matku",
    children: [
      {
        path: "/kurikulum/koordinator-matkul",
        name: "Koordinator Mata Kuliah",
        component: () => import("@/views/kurikulum_kaprodi/LihatKurikulum.vue"),
      },
      {
        path: "/kurikulum/koordinator-matkul/datamatakuliah/:id",
        name: "Data Mata Kuliah",
        component: () => import("@/views/kurikulum_kaprodi/DataMataKuliah.vue"),
      },
    ],
  },
];

export default routes_dosen;
